.InputCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.InputLabel {
  color: var(--app-gray-medium, #6d6d6d);
  letter-spacing: .28px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.InputCon2 {
  align-self: stretch;
  align-items: flex-start;
  gap: 2px;
  padding-left: 6px;
  display: flex;
}

.Input {
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  color: var(--app-charcoal-dark, #2b2b2b);
  border-radius: 12px 0 0 12px;
  flex: 1 0 0;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.InputShowHideBtn {
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  border-radius: 0 12px 12px 0;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
}

.IconsImgInputShowHide {
  height: 12px;
}

.InputMessage {
  color: #ff3c00;
  align-self: stretch;
  font-family: Roboto Mono;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.InputMessageSuccess {
  color: #06ad22;
}

.CodeInputCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 0 24px;
  display: flex;
}

.InputCodeLabel {
  color: var(--app-gray-medium, #6d6d6d);
  letter-spacing: .28px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.CodeInputCon23 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 4px;
  display: flex;
}

.CodeInput {
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  width: 100%;
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  letter-spacing: 9.12px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 12px;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.ButtonAction {
  background: var(--app-gold-yellow, #f4c542);
  height: 48px;
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .4px;
  border-radius: 17px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-flex;
}

.ButtonGhots {
  border: 1px solid var(--app-charcoal-dark, #2b2b2b);
  height: 48px;
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .4px;
  background: #fff;
  border-radius: 17px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
}

.ButtonCancel {
  background: var(--app-charcoal-dark, #2b2b2b);
  color: #fff;
  letter-spacing: .32px;
  border-radius: 17px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 48px;
  padding: 10px 24px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-flex;
}

.AccessCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 128px;
  max-width: 500px;
  margin: 0 auto;
  padding: 32px 12px;
  display: flex;
}

.AccessCon2 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  display: flex;
}

.MiniScreenContentHeader {
  color: var(--app-green, #3a7d44);
  text-align: center;
  letter-spacing: .36px;
  align-self: stretch;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.AccessCon3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 64px;
  display: flex;
}

.AccessCon4 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  padding: 0 12px;
  display: flex;
}

.LogInCon1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  max-width: 500px;
  margin: 0 auto;
  padding: 32px 12px;
  display: flex;
}

.LogInCon2 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  display: flex;
}

.LogInCon3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  padding: 0 6px;
  display: flex;
}

.LogInCon4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
}

.LogInCon5 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding: 0 12px;
  display: flex;
}

.LogInCon6 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  padding: 0 12px;
  display: flex;
}

.OtherOptionText {
  color: var(--app-gray-medium, #6d6d6d);
  text-align: center;
  letter-spacing: .32px;
  align-self: stretch;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.OtherOptionTextBtn {
  color: var(--app-green, #3a7d44);
  text-align: center;
  letter-spacing: .24px;
  text-decoration-skip-ink: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  align-self: stretch;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
}

.CreateAccountCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  max-width: 500px;
  margin: 0 auto;
  padding: 32px 12px;
  display: flex;
}

.CreateAccountCon2 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  display: flex;
}

.CreateAccountCon3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  display: flex;
}

.CreateAccountCon4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
}

.CreateAccountCon5 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 0 12px;
  display: flex;
}

.CreateAccountCon6 {
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  border-radius: 5px;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.CreateAccountCon6Accepted {
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 20px;
  height: 20px;
  display: flex;
}

.CreateAccountCon6AcceptedIcon {
  flex-shrink: 0;
  width: 16.017px;
  height: 12px;
}

.TermsAndConditionsInCreateAccount {
  color: var(--app-gray-medium, #6d6d6d);
  letter-spacing: .24px;
  text-decoration-skip-ink: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
}

.CreateAccountCon7 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding: 0 12px;
  display: flex;
}

.CreateAccountCon8 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  padding: 0 12px;
  display: flex;
}

.CreateAccountCon9 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.ConfirmEmailCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  max-width: 500px;
  margin: 0 auto;
  padding: 32px 12px;
  display: flex;
}

.ConfirmEmailCon2 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  display: flex;
}

.ConfirmEmailCon3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  display: flex;
}

.ConfirmEmailCon4 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding: 0 12px;
  display: flex;
}

.ConfirmEmailCon5 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  padding: 0 12px;
  display: flex;
}

.CreateAccountButtonChangeEmailCon1 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 12px 0;
  display: flex;
}

.CreateAccountButtonChangeEmailCon2 {
  background: var(--app-green, #3a7d44);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.CreateAccountButtonChangeEmailText {
  color: var(--app-white, #fff);
  letter-spacing: .24px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.CreateAccountDoneCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 128px;
  max-width: 500px;
  margin: 0 auto;
  padding: 32px 12px;
  display: flex;
}

.CreateAccountDoneCon2 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  display: flex;
}

.CreateAccountSecondText {
  color: var(--app-green, #3a7d44);
  text-align: center;
  letter-spacing: .64px;
  align-self: stretch;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.CreateAccountDoneCon3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  display: flex;
}

.AppLogo {
  height: 180px;
}
/*# sourceMappingURL=index.406a9ff4.css.map */
