/* Start Inputs */
.InputCon1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.InputLabel {
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}
.InputCon2 {
  display: flex;
  padding-left: 6px;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}
.Input {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  border-radius: 12px 0px 0px 12px;
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);

  flex: 1 0 0;

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.InputShowHideBtn {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 0px 12px 12px 0px;
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
}
.IconsImgInputShowHide {
  height: 12px;
}
.InputMessage {
  align-self: stretch;
  color: #ff3c00;
  font-family: "Roboto Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.InputMessageSuccess {
  color: #06ad22;
}
/* End Inputs */
/* Start InputsCode */
.CodeInputCon1 {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.InputCodeLabel {
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}
.CodeInputCon23 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}
.CodeInput {
  display: flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 100%;
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);

  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 9.12px;
}
/* End InputsCode */
/* Start Buttons */
.ButtonAction {
  display: inline-flex;
  height: 48px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 17px;
  background: var(--app-gold-yellow, #f4c542);

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
}
.ButtonGhots {
  display: inline-flex;
  height: 48px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 17px;
  border: 1px solid var(--app-charcoal-dark, #2b2b2b);
  background: #fff;

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}
.ButtonCancel {
  display: inline-flex;
  height: 48px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 17px;
  background: var(--app-charcoal-dark, #2b2b2b);

  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.32px;
}
/* End Buttons */

/* Start Options */
.AccessCon1 {
  display: flex;
  padding: 32px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 128px;
  align-self: stretch;
  max-width: 500px;
  margin: 0 auto;
}
.AccessCon2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.MiniScreenContentHeader {
  align-self: stretch;

  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
}
.AccessCon3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  align-self: stretch;
}
.AccessCon4 {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
/* End Options */
/* Start Log In */
.LogInCon1 {
  display: flex;
  padding: 32px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  /* align-self: stretch; */
  max-width: 500px;
  margin: 0 auto;
}
.LogInCon2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.LogInCon3 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
.LogInCon4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}
.LogInCon5 {
  display: flex;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.LogInCon6 {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}
.OtherOptionText {
  align-self: stretch;
  color: var(--app-gray-medium, #6d6d6d);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.32px;
}
.OtherOptionTextBtn {
  align-self: stretch;

  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}
/* End Log In */

/* Start Create Account */
.CreateAccountCon1 {
  display: flex;
  padding: 32px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  max-width: 500px;
  margin: 0 auto;
}
.CreateAccountCon2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.CreateAccountCon3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
.CreateAccountCon4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}
.CreateAccountCon5 {
  display: flex;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.CreateAccountCon6 {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--app-gray-medium, #6d6d6d);
}
.CreateAccountCon6Accepted {
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: #fff;
}
.CreateAccountCon6AcceptedIcon {
  width: 16.017px;
  height: 12px;
  flex-shrink: 0;
}
.TermsAndConditionsInCreateAccount {
  flex: 1 0 0;

  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}
.CreateAccountCon7 {
  display: flex;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.CreateAccountCon8 {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}
.CreateAccountCon9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
/* End Create Account */
/* Start Confirm Email */
.ConfirmEmailCon1 {
  display: flex;
  padding: 32px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  max-width: 500px;
  margin: 0 auto;
}
.ConfirmEmailCon2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.ConfirmEmailCon3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
.ConfirmEmailCon4 {
  display: flex;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.ConfirmEmailCon5 {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}
.CreateAccountButtonChangeEmailCon1 {
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.CreateAccountButtonChangeEmailCon2 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--app-green, #3a7d44);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.CreateAccountButtonChangeEmailText {
  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
}

/* End Confirm Email */
/* Start create account done */
.CreateAccountDoneCon1 {
  display: flex;
  padding: 32px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 128px;
  align-self: stretch;
  max-width: 500px;
  margin: 0 auto;
}
.CreateAccountDoneCon2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.CreateAccountSecondText {
  align-self: stretch;

  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: 0.64px;
}
.CreateAccountDoneCon3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
.AppLogo {
  height: 180px;
}
/* End create account done */
